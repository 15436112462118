import './App.css';
import Posts from './components/posts'

function App() {
  return (
    <div className="background">
      <Posts></Posts>
    </div>
  );
}

export default App;
